import React, { useCallback } from 'react';
import logo from './logo.svg';
import './App.css';
import { Button } from 'react-bootstrap';
import moment from 'moment';
let audio = new Audio();
async function play(file: string) {
	return new Promise(resolve => {
		try {
			audio.pause();
			if (typeof audio.onended === 'function')
				(audio as any).onended();
			audio.src = process.env.PUBLIC_URL + '/snd/' + file;
			audio.onended = resolve;
			audio.play().catch(e => { });
		} catch (e) {
		}
	})
};
let meals = [
	{
		name: 'frühstück',
		time: '8:30',
		mealTime: moment()
	},
	{
		name: 'mittagessen',
		time: '12:00',
		mealTime: moment()
	},
	{
		name: 'snack',
		time: '16:00',
		mealTime: moment()
	},
	{
		name: 'abendessen',
		time: '18:30',
		mealTime: moment()
	},
]
async function playTime(f: number) {
	f = f % 1;
	if (f < 0.15) {

	} else if (f < 0.35) {
		await play('ein-viertel.ogg')
	} else if (f < 0.65) {
		await play('ein-halb.ogg')
	} else if (f < 1) {
		await play('drei-viertel.ogg')
	}
}
async function playTime0(f: number) {
	f = f % 1;
	if (f < 0.15) {

	} else if (f < 0.35) {
		await play('viertel.ogg')
	} else if (f < 0.65) {
		await play('halben.ogg')
	} else if (f < 1) {
		await play('drei-viertel.ogg')
	}
}
async function playMeal() {
	let now = moment();
	for (let meal of meals) {
		let [h, m] = meal.time.split(':');
		meal.mealTime = moment(now).hour(parseInt(h)).minute(parseInt(m)).second(0);
	}
	for (let meal of meals) {
		let mealTime = meal.mealTime;
		if (meal === meals[0] && now.isAfter(meals[meals.length - 1].mealTime)) {
			mealTime = mealTime.add(1, 'day');
		}
		if (mealTime.isAfter(moment(now).add(-10,'minute'))) {
			//await play('drums.ogg');

			if (Math.abs(mealTime.diff(now, 'minute')) < 10) {
				await play('jetzt.ogg');
			} else {
				let hdif = (mealTime.diff(now, 'hour', true));
				if (hdif < 1) {
					await play('in.ogg');
					await play('einer.ogg');

					await playTime0(hdif);
					await play('stunde.ogg');
				}
				else if (hdif < 2) {
					await play('in.ogg');

					await play('einer.ogg');
					await playTime(hdif);
					await play('stunde.ogg');
				} else if (hdif < 3) {
					await play('in.ogg');

					await play('zwei.ogg');
					await playTime(hdif);
					await play('stunden.ogg');

				} else if (hdif < 4) {
					await play('in.ogg');

					await play('drei.ogg');
					await playTime(hdif);
					await play('stunden.ogg');

				} else if (hdif < 5) {
					await play('in.ogg');

					await play('vier.ogg');
					await playTime(hdif);
					await play('stunden.ogg');
				} else {
					await play('morgen.ogg');
				}
			}
			await play(`gibt-es-${meal.name}.ogg`);
			break;
		}
	}
}
function App() {
	let click = useCallback(() => {
		playMeal()
	}, []);
	return (
		<div className="App m-5">
			<Button onClick={click} size="lg">
				<h1>J'ai faim !!!</h1>
			</Button>
		</div>
	);
}

export default App;
